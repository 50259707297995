import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Seo from '../components/seo'
import Layout from '../components/layout'
import { title, subtitle, twitterIcon, btnWithIcon, imagesContainer, leftPhoneWrapper, rightPhoneWrapper } from '../styles/landing.module.css'

const LandingPage = () => {
    return (
        <Layout>
            <div className={"text-center pt-4"}>
                <StaticImage alt="Linx logo" src="../images/logo.svg" width={55} height={84} loading='eager' placeholder='blurred' />
                <h1 className={title + " mt-4 mb-4"}>Linx, your easy way into DeFi and NFTs on the most scalable and secure blockchain.</h1>
                <div className={subtitle}><span className={"text-secondary"}>Securely store crypto assets and interact with decentralized protocols without knowing how a blockchain works,</span> all within one app.</div>
                <a href="https://twitter.com/linx_labs" target='_blank' rel="noreferrer" className={btnWithIcon + " btn btn-primary mt-md-5 mt-4 position-relative"}>
                    <span className={twitterIcon}></span>
                    Follow us on Twitter
                </a>
                <div className={imagesContainer + ' d-flex mx-auto'}>
                    <StaticImage alt="Linx logo" src="../images/Phone 1@2x.png" className={leftPhoneWrapper} loading='eager' placeholder='blurred' />
                    <StaticImage alt="Linx logo" src="../images/Phone 2@2x.png" className={rightPhoneWrapper} loading='eager' placeholder='blurred' />
                </div>
            </div>
        </Layout>
    )
}

export const Head = ({ location }) => <Seo title="Coming soon" locationPath={location.pathname} />
export default LandingPage